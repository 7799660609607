import React from 'react'
import Div100vh from 'react-div-100vh'
import { SessionsProvider } from 'myContexts/SessionsContext'
import useSessions from 'myHooks/useSessions'
import { sessionStages } from 'myUtils/constants'
import Logo from 'myComponents/Logo'
import PoweredByConcierge from 'myComponents/PoweredByConcierge'
import TimezoneInfo from 'myComponents/TimezoneInfo'
import EventSessions from './schedule/EventSessions'
import Filter from './schedule/Filter'

const { LOADING, ERROR, SUCCESS } = sessionStages

const Schedule = () => {
  const sessionsData = useSessions()
  const { stage, logo, backgroundStyle, teamName, errorMsg, eventTimezone, loading, sessions } = sessionsData

  const getBackgroundStyle = () => {
    const { innerWidth } = window
    if (innerWidth > 900) return backgroundStyle
    return {}
  }

  return (
    <SessionsProvider value={sessionsData}>
      <Div100vh>
        <div style={getBackgroundStyle()} className='web-SmsOptIn'>
          <div className='bg-white h-100 w-full sm:w-[400px]'>
            {stage === LOADING && <div className='flex justify-center items-center h-full'>Loading...</div>}
            {stage === ERROR && <div className='web-SmsForm-error'><p>{errorMsg}</p></div>}
            {stage === SUCCESS && (
              <div className='overflow-x-hidden overflow-y-scroll px-[1.25rem] flex flex-col h-100'>
                <div className="flex-shrink-0">
                  {logo && <Logo logo={logo} />}
                </div>
                <div className='web-SmsForm-title mb-[.75rem] px-1'>{teamName}</div>
                <Filter />
                <TimezoneInfo timezone={eventTimezone} />
                <EventSessions loading={loading} eventTimezone={eventTimezone} sessions={sessions} />
                <PoweredByConcierge />
              </div>
            )}
          </div>
        </div>
      </Div100vh>
    </SessionsProvider>
  )
}

export default Schedule
