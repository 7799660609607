import React from 'react';
import { formatStartEndDate } from 'myUtils/formatter';
import { MapPinIcon } from "@heroicons/react/20/solid";

const EventSessions = ({ loading, eventTimezone, sessions }) => {
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : sessions && sessions.length > 0 ? (
        <div className='flex flex-col gap-6 px-1 mb-2'>
          {sessions.map((session) => (
            <div className='text-left event-sessions' key={session.id}>
              <h3 className='font-16 font-semibold text-gray-900 mb-1'>{session.name}</h3>

              <div className='mt-2 flex flex-col text-gray-500'>
                <div className='flex items-start space-x-3 pb-2'>
                  <p className='font-14 mb-0'>
                    {formatStartEndDate(session.startDatetimeUtc, session.endDatetimeUtc, eventTimezone)}
                  </p>
                </div>
              </div>
              <div className='flex flex-col text-gray-500 pb-3'>
                {session.locationName && (
                  <div className="flex items-start space-x-1">
                    <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    {session.locationUrl ? (
                      <a 
                        href={session.locationUrl}
                        className='font-14 no-underline' 
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {session.locationName}
                      </a>
                    ) : (
                      <span className="font-14">{session.locationName}</span>
                    )}
                  </div>
                )}                
              </div>

              {session.description && (
                <p className='mb-0 pb-2' style={{ whiteSpace: 'pre-line' }}>{session.description}</p>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>No events found.</p>
      )}
    </>
  );
}

export default EventSessions;
