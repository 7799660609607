import { getTimeZone, timeZoneAbbreviation } from 'myUtils/formatter'

const TimezoneInfo = ({ timezone }) => {
  return (
    <p className='mt-1 text-xs text-slate-500'>
      All times in {getTimeZone(timezone)} ({timeZoneAbbreviation(timezone)})  
    </p>
  );
}

export default TimezoneInfo;